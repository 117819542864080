@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Permanent Marker';
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  border: none;
  background-color: transparent;
  font: inherit;
}

button:focus, button:active {
          outline: 0;
}

button:hover:enabled {
  cursor: pointer;
}

input {
  border:none;
  font-family: Roboto;
  background-size: 100% 100%;
  height: 40px;
  width: 200px;
}

input:focus, input:active {
  outline: 0;
}



.players-component {
    width: 90%;
    margin : auto auto;
}

.players-list-component {
    display: flex;
    justify-content: space-evenly;
}

.players-button-component {
    display: flex;
    justify-content: space-evenly;
}

.players-list {
    width:25%;
    font-size: 1vw;
    padding: 10px 0;
    margin: auto auto;
}

.players-list p {
    line-height: 1.5vw;
}

.green-players-list {
    color: #97DABD;
}

.red-players-list {
    color: #FF644F;
}

.leader-player {
    text-decoration: underline;
}

@media (max-aspect-ratio: 1/1) {
    .players-list {
        font-size: 2vw;
    }

    .players-list p {
        line-height: 2vw;
    }
}

.game-component {
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    --upper-height: 250px;
}

.game-very-upper-container {
    height: 0;
    display:flex;
}

.game-upper-container {
    height: var(--upper-height);
    padding:calc(var(--upper-height) / 25) 0;
    display:flex;
    justify-content: space-between;
    line-height: 2.5vw;
}

.game-upper-left, .game-upper-right {
    width: 5%;
    height:var(--upper-height);
}

.game-upper-title, .game-very-upper-title {
    width: 25%;
    display: flex;
    vertical-align: center;
    margin: auto auto;
    max-height: 100%;
}

.game-upper-title img, .game-very-upper-title img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: 0 auto;
}

.game-upper-playing {
    width: 25%;
    padding-top: calc(var(--upper-height) / 25);
    margin: auto auto auto 4vw;
    font-size: 1.5vw;
}

.game-upper-buttons {
    margin: auto auto;
    display: flex;
    justify-content: space-evenly;
    padding-top: calc(var(--upper-height) / 15);
}

.game-upper-players {
    width: 25%;
    margin: auto 4vw auto auto;
}

.game-words-container {
    margin: 2vh auto;
    padding: 2vh 1vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: calc(96vh - 6 * var(--upper-height) / 5);
    width: 95%;
    background-color: white;
    border-radius: 20px;
}

.game-lower-container {
    height: calc(var(--upper-height) / 5);
}

.word-component {
    width : 20%;
    height : 20%;
    display : flex;
}

@media (max-height: 650px) {
    .game-component {
        --upper-height: calc(100vh - 400px);
    }
}

@media (max-height: 500px) {
    .game-component {
        --upper-height: 100px;
    }
}

@media (max-aspect-ratio: 1/1) {
    .game-very-upper-container {
        height: calc(var(--upper-height) * 1 /2);
        padding: calc(var(--upper-height) / 25) 0 0;
    }

    .game-upper-container {
        height: calc(var(--upper-height) * 1 / 2);
        padding: 0 0 calc(var(--upper-height) / 25);

    }

    .game-very-upper-title {
        width: 100%;
    }

    .game-upper-title {
        width: 0;
    }

    .game-upper-playing, .game-upper-players {
        width : 35%;
        font-size: 2vw;
        line-height: 3vw;
        margin: auto auto auto auto;
    }

    .game-upper-left, .game-upper-right {
        height: calc(var(--upper-height) * 1 / 2);
        width : 10%;
    }
}






.menu-component {
    width: 20vw;
    min-width: 200px;
    height: 100%;
    padding : 2rem 0;
    overflow-y: auto;
    direction: rtl;
    margin: 0 0;
}

.new-game-component {
    margin : 2rem auto;
    height: 150px;
    display: flex;
    flex-direction: column;
    color: #0147F7;
    direction: ltr;
    direction: initial;
}

.menu-game-element {
    margin : 0.5rem auto;
    max-width: 100%;
}

.new-game-component input {
    background-image: url(/static/media/BORD_ROUGE.bd91e6e0.png);
    margin: 1em auto;
    max-width: 90%;
}


.game-page {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.main-menu {
    height : 100%;
}

.main-rules {
    height : 100%;
}

.main-game {
    height : 100%;
    flex-basis: 100%;
    background-image: url(/static/media/PLATEAU.d1ed54fd.png);
}


.rules-component {
    width: 20vw;
    min-width: 250px;
    height: 100%;
    padding: 1vh 2vw;
    overflow-y: auto;
}

.rules-inner-component {
    width: 100%;
    margin: auto auto;
}

.rules-component p, .rules-component li {
    text-align: justify;
    font-size: 13px;
    font-family: 'Roboto';
}

.rules-component h1 {
    font-size: 20px;
}
.authentication-page input {
    margin:5px 0;
    background-image: url(/static/media/BORD_JAUNE.ea7c47ef.png);
}

.authentication-page button {
    margin: 20px auto;
}

.authentication-page p {
    margin: 20px 0;
    color: #FF644F;
}

.authentication-title{
    margin: auto auto;
}

.authentication-title img{
    width: auto;
    height: 200px;
    background-size: cover;
}
