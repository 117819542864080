@import "../App.css";

.game-component {
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    --upper-height: 250px;
}

.game-very-upper-container {
    height: 0;
    display:flex;
}

.game-upper-container {
    height: var(--upper-height);
    padding:calc(var(--upper-height) / 25) 0;
    display:flex;
    justify-content: space-between;
    line-height: 2.5vw;
}

.game-upper-left, .game-upper-right {
    width: 5%;
    height:var(--upper-height);
}

.game-upper-title, .game-very-upper-title {
    width: 25%;
    display: flex;
    vertical-align: center;
    margin: auto auto;
    max-height: 100%;
}

.game-upper-title img, .game-very-upper-title img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: 0 auto;
}

.game-upper-playing {
    width: 25%;
    padding-top: calc(var(--upper-height) / 25);
    margin: auto auto auto 4vw;
    font-size: 1.5vw;
}

.game-upper-buttons {
    margin: auto auto;
    display: flex;
    justify-content: space-evenly;
    padding-top: calc(var(--upper-height) / 15);
}

.game-upper-players {
    width: 25%;
    margin: auto 4vw auto auto;
}

.game-words-container {
    margin: 2vh auto;
    padding: 2vh 1vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: calc(96vh - 6 * var(--upper-height) / 5);
    width: 95%;
    background-color: white;
    border-radius: 20px;
}

.game-lower-container {
    height: calc(var(--upper-height) / 5);
}

.word-component {
    width : 20%;
    height : 20%;
    display : flex;
}

@media (max-height: 650px) {
    .game-component {
        --upper-height: calc(100vh - 400px);
    }
}

@media (max-height: 500px) {
    .game-component {
        --upper-height: 100px;
    }
}

@media (max-aspect-ratio: 1/1) {
    .game-very-upper-container {
        height: calc(var(--upper-height) * 1 /2);
        padding: calc(var(--upper-height) / 25) 0 0;
    }

    .game-upper-container {
        height: calc(var(--upper-height) * 1 / 2);
        padding: 0 0 calc(var(--upper-height) / 25);

    }

    .game-very-upper-title {
        width: 100%;
    }

    .game-upper-title {
        width: 0;
    }

    .game-upper-playing, .game-upper-players {
        width : 35%;
        font-size: 2vw;
        line-height: 3vw;
        margin: auto auto auto auto;
    }

    .game-upper-left, .game-upper-right {
        height: calc(var(--upper-height) * 1 / 2);
        width : 10%;
    }
}





