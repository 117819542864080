@import "../App.css";

.authentication-page input {
    margin:5px 0;
    background-image: url('../resources/BORD_JAUNE.png');
}

.authentication-page button {
    margin: 20px auto;
}

.authentication-page p {
    margin: 20px 0;
    color: #FF644F;
}

.authentication-title{
    margin: auto auto;
}

.authentication-title img{
    width: auto;
    height: 200px;
    background-size: cover;
}