@import "../App.css";

.game-page {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.main-menu {
    height : 100%;
}

.main-rules {
    height : 100%;
}

.main-game {
    height : 100%;
    flex-basis: 100%;
    background-image: url('../resources/PLATEAU.png');
}

