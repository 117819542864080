@import "../App.css";

.players-component {
    width: 90%;
    margin : auto auto;
}

.players-list-component {
    display: flex;
    justify-content: space-evenly;
}

.players-button-component {
    display: flex;
    justify-content: space-evenly;
}

.players-list {
    width:25%;
    font-size: 1vw;
    padding: 10px 0;
    margin: auto auto;
}

.players-list p {
    line-height: 1.5vw;
}

.green-players-list {
    color: #97DABD;
}

.red-players-list {
    color: #FF644F;
}

.leader-player {
    text-decoration: underline;
}

@media (max-aspect-ratio: 1/1) {
    .players-list {
        font-size: 2vw;
    }

    .players-list p {
        line-height: 2vw;
    }
}
