@import "../App.css";

.rules-component {
    width: 20vw;
    min-width: 250px;
    height: 100%;
    padding: 1vh 2vw;
    overflow-y: auto;
}

.rules-inner-component {
    width: 100%;
    margin: auto auto;
}

.rules-component p, .rules-component li {
    text-align: justify;
    font-size: 13px;
    font-family: 'Roboto';
}

.rules-component h1 {
    font-size: 20px;
}