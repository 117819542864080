@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

* {
  font-family: 'Permanent Marker';
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  border: none;
  background-color: transparent;
  font: inherit;
}

button:focus, button:active {
          outline: 0;
}

button:hover:enabled {
  cursor: pointer;
}

input {
  border:none;
  font-family: Roboto;
  background-size: 100% 100%;
  height: 40px;
  width: 200px;
}

input:focus, input:active {
  outline: 0;
}


