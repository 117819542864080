@import "../App.css";

.menu-component {
    width: 20vw;
    min-width: 200px;
    height: 100%;
    padding : 2rem 0;
    overflow-y: auto;
    direction: rtl;
    margin: 0 0;
}

.new-game-component {
    margin : 2rem auto;
    height: 150px;
    display: flex;
    flex-direction: column;
    color: #0147F7;
    direction: initial;
}

.menu-game-element {
    margin : 0.5rem auto;
    max-width: 100%;
}

.new-game-component input {
    background-image: url('../resources/BORD_ROUGE.png');
    margin: 1em auto;
    max-width: 90%;
}

